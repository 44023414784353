import React from "react";
import "./Three.css";
import ip from "./inPersonSchedule.png";
import vs from "./virtualSchedule.png";
import ab from "./AbstractBooklet.png";
export default function Three() {
  return (
    <div className="three" id="dates">
      <div className="wrapper">
        <h1>Important Dates</h1>
        <hr />

        <div className="timeline">
          <ul>
            <li>
              <strong>Last Date of Abstract Submissions:</strong>{" "}
              <strike>January 10, 2024</strike>
            </li>
            <li>
              <strong>Notification of Acceptance:</strong>{" "}
              <strike> January 12, 2024</strike>
            </li>
            {/* <li>
              <strong>Early Bird Registration Start:</strong> October
              01,2023-December 15,2023
            </li> */}
            {/* <li>
              <strong>Full Paper (Selected) Submission:</strong> January 30,
              2024
            </li> */}
            <li>
              <strong>Conference Date:</strong> February 22-24, 2024
            </li>
          </ul>
        </div>
        <div className="schedule">
          <div className="schedule-button">
            <a
              href="https://cdn.discordapp.com/attachments/1096822198775316490/1209499048822710292/Conference_Schedule_In-person-updated.pdf?ex=66154944&is=6602d444&hm=6ae034f0e5aa1407cc5389011a1f9c26597f174db28894a6d58496ec633af705&"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={ip}
                alt="In-Person Schedule QR Code"
                className="qrcode qrcode1"
              />
              <button>In-Person Schedule</button>
            </a>
          </div>
          <div className="schedule-button">
            <a
              href="https://cdn.discordapp.com/attachments/1096822198775316490/1209502935814508616/Conference_Schedule_Virtual-updated.pdf?ex=66154ce3&is=6602d7e3&hm=9a17a63e9f28e0e4c0ff277c73bb983f5c8de8d5ec5e88cf7540f236f1aee4fd&"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={vs} alt="Virtual Schedule QR Code" className="qrcode" />
              <button>Virtual Schedule</button>
            </a>
          </div>
          <div className="schedule-button">
            <a
              href="https://cdn.discordapp.com/attachments/1096822198775316490/1209905708292902962/ABSTRACT_BOOKLET_SDCEE.pdf?ex=6616c3ff&is=66044eff&hm=4285f34bbc822d4ac9a83d32ff2c2a32e2be99433e9cb14bd1c30443199cc53d&"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ab} alt="Virtual Schedule QR Code" className="qrcode" />
              <button>Abstract Booklet</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
